<template>
  <div class="form-container">
    <div class="container">
      <div class="wrapper">
        <div class="employ-info">
          <!--          <h1>-->
          <!--            Refer people to the Refugee Employability Programme-->
          <!--          </h1>-->

          <ul>
            <li>
              <p>
                {{$t('ifYouKnowRefugeesWhoAreLookingForAJobOrCouldBenefi')}}
              </p>
            </li>
            <li>
              <p>
                {{$t('weCanSupportThemIfThey')}}
              </p>
            </li>
            <li>
              <img :alt="$t('done')" src="@/assets/icons/done_all.svg">
              <span>
                {{ $t('liveInTheSouthWest')}}
                <a class="link-modal" href="https://www.gov.uk/find-local-council" target="_blank">
                  {{ $t('clickHereToFindYourLocalCouncilByPostcode') }}
                </a>
              </span>

            </li>

            <li>
              <img :alt="$t('done')" src="@/assets/icons/done_all.svg">
              <p>{{$t('betweenTheAgeOf18And66StatePensionAge2')}}</p>
            </li>

            <li>
              <img :alt="$t('done')" src="@/assets/icons/done_all.svg">
              <p>{{$t('haveEitherIndefiniteLeaveToRemainAlsoKnownAsSettle')}}</p>
            </li>

            <li>
              <img :alt="$t('done')" src="@/assets/icons/done_all.svg">
              <p>{{$t('arrivedInTheUkByCertainRoutesNotIncludingHomesForU')}}
                <a @click="openModal">{{$t('clickHereForMoreDetails')}}</a>
                <Modal
                    :is-modal-visible="isModalVisible"
                    @close="closeModal"
                >
                  <template v-slot:modal>
                    <div class="modal-content">
                      <div class="block">
                        <p>
                          {{$t('youMustHaveArrivedInTheUkUnderOneOfTheFollowingRou')}}
                        </p>

                        <ul class="modal" :class="[{'content-ar': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                          <li>
                            {{$t('ukResettlementSchemeUkrs')}}
                          </li>
                          <li>
                            {{$t('afghanCitizensResettlementSchemeAcrs')}}
                          </li>
                          <li>
                            {{$t('afghanRelocationsAndAssistancePolicyArap')}}
                          </li>
                          <li>
                            {{$t('communitySponsorshipSchemeIfYouArrivedOnOrAfter25F')}}
                          </li>
                        </ul>
                      </div>

                      <div class="block">
                        <p>{{$t('youCanGetExtraSupportFocusedOnIntegrationAndEnglis')}}</p>

                        <ul class="modal" :class="[{'content-ar': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                          <li>
                            {{$t('refugeePermissionToStayIfYouWereGrantedPermissionT')}}
                          </li>
                          <li>
                            {{$t('mandateResettlementSchemeIfYouArrivedOnOrAfter25Fe')}}
                          </li>
                          <li>
                            {{$t('refugeeFamilyReunionIfYourSponsoringRefugeeIsEligi')}}
                          </li>
                          <li>
                            {{ $t('refugeeFamilyReunionBulletAndTextBox') }}
                          </li>

                        </ul>
                      </div>

                      <div class="block">
                        <p>
                          {{$t('forMoreInformationPleaseReferToTheUkGovernmentRepP')}}
                        </p>
                        <p>
                          {{$t('emailRepswrescueorg')}}
                        </p>
                        <p>
                          {{$t('freeTelephone08082940124')}}
                        </p>
                      </div>

                    </div>
                  </template>
                </Modal>
              </p>
            </li>

            <li>
              <img :alt="$t('done')" src="@/assets/icons/done_all.svg">
              <p>{{$t('youAreActivelySeekingWorkAndNotInFullTimeEducation')}}</p>
            </li>
            <li>
              <p>Click here to <a href="https://rep.mendee.digital/leaflets" style="color: #184892;">download</a>
                {{$t('ourFlyersInDifferentLanguagesAndShareThemWidelyGet')}}
                <br>
                <a :href="'mailto:' + $t('mailrep.sw@rescue.org')">{{ $t('mailrep.sw@rescue.org') }}</a></p>


            </li>
          </ul>

        </div>
      </div>

    </div>
  </div>
</template>
<script>
import ReferFormComponent from "@/components/RefererFormComponent.vue";
import Modal from "@/components/Modal.vue";
import ReferSomeonePage from "@/views/ReferSomeonePage.vue";

export default {
  name: 'FormBlock',
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },
  components: {
    ReferFormComponent,
    ReferSomeonePage,
    Modal,

  }
}
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  //min-height: 687px;
  background-color: #92C5EB;
  padding: 44px 0;

  .form-wrapper {
    width: 59%;
    height: 1200px;

    @media (max-width: 1224px) {
      width: 100%;
      height: 1200px;
    }
  }

  li, p, span{
    direction: ltr!important;
  }

  @media (max-width: 1224px) {
    padding: 64px 16px;
  }
}

.employ-info {
  width: 41%;

  h1 {
    font-size: calc(var(--main-font-size) + 32px);
    font-weight: 700;
    line-height: 124%;
    color: $main-color;
  }

  ul {
    width: 70%;
    list-style: none;
    //margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 1224px) {
      width: 100%;
    }
  }

  li {
    font-size: var(--main-font-size);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;

    a {
      color: $main-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .modal {
    ul {
      width: 100%;
      padding: 0px;
    }

    li {
      //flex-direction: row-reverse;
      display: block;

    }
  }

  .modal.content-ar {

    li {
      display: flex;
    }

  }

  @media (max-width: 1224px) {
    width: 100%;
    margin-bottom: 64px;

    h1 {
      font-size: calc(var(--main-font-size) + 8px);
      width: 328px;
    }
  }

}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1224px) {
    flex-direction: column;
  }
}
</style>