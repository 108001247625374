<template>
  <div class="container">
    <div class="container-all-news">
      <div class="news-header">
        <img alt="" :src="getFile(news.logo)">
        <p class="title">
          <span>{{ formatPublicationDate(news.createdAt) }}</span>
          {{ news.title }}
        </p>
      </div>
      <div class="body-news" ref="newsBody" v-html="cleanHtml(news.body)"></div>
    </div>

<!--    <faq-component/>-->

    <event-component v-if="news.events.length > 0" :events="events"/>

    <top-stories v-if="news.story.length > 0" :stories="news.story"/>

    <div class="leaflets" v-if="news.leaflet.length > 0">
      <p class="title">Leaflets</p>
      <leaflets-component :leaflets="news.leaflet"/>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import sanitizeHTML from "sanitize-html";
import Accordion from "@/components/Accordion.vue";
import FaqComponent from "@/components/faqComponent.vue";
import EventComponent from "@/components/eventComponent.vue";
import TopStories from "@/components/TopStories.vue";
import LeafletsComponent from "@/components/leafletsComponent.vue";
import {format} from "date-fns";

export default {
  name: 'NewsReadMore',
  components: {LeafletsComponent, TopStories, EventComponent, FaqComponent, Accordion},
  props: ['url'],
  data() {
    return {
      news: {},
      urlFromLink: this.url,
      events:[]
      // leaflets: [],
    };
  },
  watch: {
    news: {
      handler() {
        this.$nextTick(() => {
          this.wrapImages();
        });
      },
      deep: true,
    },
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}/news/${this.urlFromLink}`)
        .then((response) => {
          this.news = response.data.news;
          this.events = this.news.events;
        });
    console.log(this.news)
  },
  methods: {
    formatPublicationDate(dateString) {
      return format(new Date(dateString), 'EEEE, MMMM d, yyyy');
    },
    getFile(url) {
      return process.env.VUE_APP_API_URL + url;
    },
    cleanHtml(unsafeContent) {
      return sanitizeHTML(unsafeContent, {
        allowedTags: [
          "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
          "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
          "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
          "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
          "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
          "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
          "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "img"
        ],
        allowedAttributes: {
          a: ['href', 'name', 'target'],
          img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading']
        },
      });
    },
    wrapImages() {
      const container = this.$refs.newsBody;
      if (!container) return;
      const images = container.querySelectorAll('img:not(.wrapped)');
      images.forEach(img => {
        console.log("img");
        const wrapper = document.createElement('div');
        wrapper.classList.add('image-wrapper');
        img.parentNode.insertBefore(wrapper, img);

        const innerDiv = document.createElement('div');
        innerDiv.classList.add('inner-image-wrapper');
        wrapper.appendChild(innerDiv);
        innerDiv.appendChild(img);

        if(img.alt !== ''){
          const descriptionDiv = document.createElement('div');
          descriptionDiv.classList.add('image-description');
          const descriptionText = document.createElement('p');
          descriptionText.innerHTML = img.alt.replace(/\n/g, '<br>');
          descriptionDiv.appendChild(descriptionText);
          wrapper.appendChild(descriptionDiv);
        }

        img.classList.add('wrapped');
      });
    }
  }
};
</script>


<style scoped lang="scss">

@import "@/assets/styles/vdeep.scss";

.container{
  padding: 0 1rem;
  text-align: start;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  .leaflets{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .title{
      color: #024694;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 0.72px;
      margin: 0;
    }
  }
}
.container-all-news {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  //margin: 4rem 0 7.5rem;

  .news-header {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 4rem;

    .title {
      color: #024694;
      font-size: 48px;
      font-style: normal;
      //font-weight: bold;
      line-height: 124%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      direction: ltr;

      span {
        color: #373737;
        font-size: calc(var(--main-font-size) - 2px);
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .chapter {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .chapter-title {
      color: #184892;
      font-size: calc(var(--main-font-size) + 2px);
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }

    .chapter-text {
      color: #000;
      font-size: calc(var(--main-font-size) + 2px);
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      a{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>