<template>
  <div class="form-container">
    <div class="container">
      <div class="wrapper">
        <div class="employ-info">
          <h1>
            {{ $t('refugeeNowClosed') }}
          </h1>
          <br>

          <p :class="[{'align': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
            <strong>{{ $t('refugeeWhoWithUs') }}</strong></p>

          <Modal
              :is-modal-visible="isModalVisible"
              @close="closeModal"
          >
            <template v-slot:modal>
              <div class="modal-content">
                <div class="block">
                  <p>
                    {{ $t('youMustHaveArrivedInTheUkUnderOneOfTheFollowingRou') }}
                  </p>

                  <ul :class="[{'content-ar': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]"
                      class="modal">
                    <li>
                      {{ $t('ukResettlementSchemeUkrs') }}
                    </li>
                    <li>
                      {{ $t('afghanCitizensResettlementSchemeAcrs') }}
                    </li>
                    <li>
                      {{ $t('afghanRelocationsAndAssistancePolicyArap') }}
                    </li>
                    <li>
                      {{ $t('communitySponsorshipSchemeIfYouArrivedOnOrAfter25F') }}
                    </li>
                  </ul>
                </div>

                <div class="block">
                  <p>{{ $t('youCanGetExtraSupportFocusedOnIntegrationAndEnglis') }}</p>

                  <ul class="modal" :class="[{'content-ar': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                    <li>
                       {{ $t('refugeePermissionToStayIfYouWereGrantedPermissionT') }}
                    </li>
                    <li>
                       {{ $t('mandateResettlementSchemeIfYouArrivedOnOrAfter25Fe') }}
                    </li>
                    <li>
                       {{ $t('refugeeFamilyReunionIfYourSponsoringRefugeeIsEligi') }}
                    </li>
                    <li>
                       {{ $t('refugeeFamilyReunionBulletAndTextBox') }}
                    </li>
                  </ul>
                </div>

                <div class="block">
                  <p>
                    {{ $t('forMoreInformationPleaseReferToTheUkGovernmentRepP') }}
                  </p>
                  <p>
                    {{ $t('emailRepswrescueorg') }}
                  </p>
                  <p>
                    {{ $t('freeTelephone08082940124') }}
                  </p>
                </div>

              </div>
            </template>
          </Modal>

        </div>
      </div>

    </div>
  </div>
</template>
<script>
import JotFormComponent from "@/components/JotFormComponent.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: 'FormBlock',
  props: ['selectedLanguage'],
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },
  components: {
    Modal,
    JotFormComponent
  }
}
</script>

<style lang="scss" scoped>
.scale {
  transform: scale(-1, 1)
}
.align{align-items: start}
.form-container {
  width: 100%;
  background-color: #92C5EB;
  padding: 44px 0;

  .form-wrapper {
    width: 59%;
    height: 1200px;
    //height: 900px;

    @media (max-width: 1224px) {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1224px) {
    padding: 64px 16px;
  }
}

.employ-info {
  width: 41%;

  h1 {
    font-size: calc(var(--main-font-size) + 32px);
    font-weight: 700;
    line-height: 124%;
    color: $main-color;
  }

  ul {
    //width: 70%;
    list-style: none;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 1224px) {
      width: 100%;
    }
  }

  li {
    font-size: var(--main-font-size);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;

    a {
      color: $main-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .modal {
    ul {
      width: 100%;
      padding: 0px;
    }

    li {
      //flex-direction: row-reverse;
      display: block;

    }
  }

  .modal.content-ar {

    li {
      display: flex;
    }

  }

  @media (max-width: 1224px) {
    width: 100%;
    margin-bottom: 64px;

    h1 {
      font-size: calc(var(--main-font-size) + 8px);
    }
  }

}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;

  @media (max-width: 1224px) {
    flex-direction: column;
  }
}
</style>