<template>
  <div v-if="showBlock" class="block">
    <div class="block-overlay" @click="closeBlock"></div>

    <ul class="nav-list">
      <li @click="closeBlock()">
        <router-link class="link" to="/">{{ $t('home') }}</router-link>
      </li>
      <li @click="closeBlock()">
        <router-link class="link" to="/about-us">{{ $t('aboutUs') }}</router-link>
      </li>

      <li @click="closeBlock()">
        <router-link class="link" to="/news">{{ $t('newsUs') }}</router-link>
      </li>

      <li @click="closeBlock()">
        <router-link class="link" to="/contact">{{ $t('contactUs') }}</router-link>
      </li>


    </ul>
    <div class="language-select">
      <div class="selected notranslate" @click="toggleLanguagesDropdown">
        <img alt="" src="@/assets/icons/language.svg">
        {{ titleSelectedLangProp }}
        <img class="rotate-close" :class="{ 'rotate-open': dropdownIsOpen === true }"
             alt="" src="@/assets/icons/expand_more.svg" >
      </div>


      <div v-if="dropdownIsOpen" class="options notranslate">
        <a class="option"
           @click="changeLanguageEn"
        >
          <img alt="" src="@/assets/icons/language.svg">
          {{ $t('eng') }}
        </a>

        <a class="option"
           @click="changeLanguagePrs"
        >
          <img alt="" src="@/assets/icons/language.svg">
          {{ $t('dari') }}
        </a>

        <a class="option"
           @click="changeLanguageAr"
        >
          <img alt="" src="@/assets/icons/language.svg">
          {{ $t('arabic') }}
        </a>

        <a class="option"
           @click="changeLanguagePs"
        >
          <img alt="" src="@/assets/icons/language.svg">
          {{ $t('pashto') }}
        </a>

        <a v-for="language in languages"
           class="option"
           @click="changeLangByValue(language)"
        >
          <img alt="" src="@/assets/icons/language.svg">
          {{ language.text }}
        </a>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'HeaderMobileMenu',
  data() {
    return {
      inputText: '',
      dropdownIsOpen: false,
      selectedLanguage: 'en',
    };
  },
  props: ['showBlock', 'languages', 'titleSelectedLangProp'],
  methods: {
    scrollToForm() {
      if (this.$route.name !== 'home') {
        this.$router.push({path: '/'});
        setTimeout(() => {
          document.getElementById('form')?.scrollIntoView({behavior: 'smooth'})
        }, 100);
      }
      this.closeBlock();
      document.getElementById('form').scrollIntoView({behavior: 'smooth'})
    },
    closeBlock() {
      this.$emit('closeBlock');
    },
    changeLangByValue(language) {
      this.$emit('language-clicked', language.value);
    },
    toggleLanguagesDropdown() {
      this.dropdownIsOpen = !this.dropdownIsOpen
    },
    changeLanguageEn() {
      this.$emit('lang-en');
    },
    changeLanguagePrs() {
      this.$emit('lang-prs');
    },
    changeLanguageAr() {
      this.$emit('lang-ar');
    },
    changeLanguagePs() {
      this.$emit('lang-ps');
    },
  },

}
</script>

<style lang="scss" scoped>
.rotate-open {
  transform: rotate(180deg)!important;
  transition: transform 0.3s!important;
}

.rotate-close{
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.language-select {
  color: #ffffff;
  align-items: center;
  font-size: var(--main-font-size);
  font-weight: 600;
  gap: 4px;
  position: relative;
  margin: 0 3rem;

  .selected {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    transition: opacity .2s ease-in-out;
    margin-bottom: 1rem;

    &:hover {
      opacity: .8;
      cursor: pointer;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    background-color: $main-color;
    overflow: auto;
    max-height: 8rem;
    left: -7px;
    z-index: 10;
  }
  .options::-webkit-scrollbar {
    width: 0px;
  }

  .option {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border-bottom: 1px solid #ffffff;
    transition: opacity .2s ease-in-out;

    &:hover {
      cursor: pointer;
      opacity: .8;
    }

    &:last-child {
      border: none;
    }
  }

}

.block {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  height: fit-content;
  border-radius: 0 0 16px 16px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  background: #034694;
  transition: top 0.5s;
  top: 99%;
  padding: 1rem 0;

  .container-search {
    .box-search {
      position: relative;
      padding: 0 1rem;

      .search {
        width: 100%;
        padding: 1rem 1rem 1rem 3rem;
        background: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid #FFF;
        color: #fff;
        font-size: var(--main-font-size);
        font-style: normal;
        font-weight: 600;
        line-height: 125%;

        &::placeholder {
          color: rgba(255, 255, 255, 0.48);
        }
      }

      img {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(-50%, -50%);
        filter: brightness(0) invert(1);
      }
    }

    .search-result {
      padding: 3rem 1rem;
      background: #fff;
      display: flex;
      flex-direction: column;

      .title {
        color: #0A0A0A;
        font-size: var(--main-font-size);
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
      }

      .result-list {
        display: flex;
        flex-direction: column;
        max-height: 50vh;
        overflow: auto;

        .list-item {
          display: flex;
          padding: 1rem 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          gap: 1.5rem;

          p {
            color: #000;
            margin: 0;
            font-size: calc(var(--main-font-size) + 2px);
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }

          .coincidences {
            color: #3B6AD0;
          }
        }

        .list-item:last-child {
          border: none;
        }
      }

    }
  }

  .body-mobile-menu {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0 4rem;


    .links {
      color: #FFF;
      font-size: calc(var(--main-font-size) + 8px);;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      margin: 0;
      text-align: center;
      text-decoration: none;
    }


  }

  .nav-list {
    text-align: center;
    padding: 0 1rem;

    li {
      list-style-type: none;

      .link {
        font-size: calc(var(--main-font-size) + 4px);;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
      }
    }
  }
}

.block-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .block {
    .header-mobile-menu {
      padding: 1.5rem 2.5rem 0;
    }

    .container-search {

      .search-result {
        padding: 2rem 2.5rem;
      }
    }

    .body-mobile-menu {
      padding: 0 2.5rem 1.5rem;
    }
  }
}

@media only screen and (max-width: 567px) {
  .block {

    .header-mobile-menu {
      padding: 1.5rem 1.5rem 0;
    }

    .container-search {

      .search-result {
        padding: 2rem 1.5rem;
      }
    }

    .body-mobile-menu {
      padding: 0 1.5rem 1.5rem;
    }
  }
}
</style>